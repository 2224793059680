import React, {useState, useEffect} from 'react'

import '../App.css'
import {Link} from 'react-router-dom'

import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Marker from '../components/MapMarker';
import MapComponent from '../components/MapComponent'

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, doc, getDoc } from "firebase/firestore"

import icon from '../components/map_icon_20.png'

const firebaseConfig = {
  apiKey: "AIzaSyCd1A1LhYXHBz_SlFSkc5lIwigR6ZuoBqM",
  authDomain: "cchimekichara.firebaseapp.com",
  projectId: "cchimekichara",
  storageBucket: "cchimekichara.appspot.com",
  messagingSenderId: "318110024658",
  appId: "1:318110024658:web:3aae784a4459144ce4fe0b",
  measurementId: "G-76JLXVYWSC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore()



const HomeScreen = () => {
  
  return (
    <div className='container'>
      <HeroSection />
      <div className='spacer'></div>
      <IntroductionSection />
      <div className='spacer'></div>
      <ResultsSection />
    </div>
  )
}

const HeroSection = (props) => {
  return (
    <div className='hero-container'>
      <div className='hero-title'>
        <h1>Chhimeki Chara</h1>
      </div>
    </div>
  )
}

const ResultsSection = (props) => {
  const [resultsDate, setResultsDate] = useState('xx-xx-xxxx')
  const [resultsDates, setResultsDates] = useState(undefined)
  const [refresh, setRefresh] = useState(false)
  const [refresh2, setRefresh2] = useState(false)

  const [resultsData, setResultsData] = useState(undefined)

  const [selectedDistrict, setSelectedDistrict] = useState('Nepal')
  const [districts, setDistricts] = useState([])

  const [display1, setDisplay1] = useState(undefined)
  const [display2, setDisplay2] = useState(undefined)

  const [showMore, setShowMore] = useState(false)


  useEffect(()=>{
    if (resultsDates == undefined) {
      getResultDates()
    }
    if (resultsDate != 'xx-xx-xxxx' && resultsDate != undefined){
      getResultsData()
    }
  }, [refresh])

  useEffect(()=>{
    if (resultsData != undefined) {
      assortData(resultsData)
    }
  }, [refresh2])

  async function getResultDates() {
    const dates = await getDocs(collection(db, 'finalresults'))
    let resDates = []
    dates.forEach(el => {
      resDates.push(el.id)
    })

    // setResultsDates(sortDates(resDates))
    let finalDates = sortDates(resDates)
    console.log(finalDates)
    setResultsDates(resDates)
    setResultsDate(resDates[0])
    getResultsData(resDates[0])
  }

  function sortDates(dates){
    let sortedDates = dates
    sortDates = dates.sort((a, b) => {
      return Number(a.split('-')[0]) - Number(b.split('-')[0])
    })
    sortDates = dates.sort((a, b) => {
      return Number(a.split('-')[1]) - Number(b.split('-')[1])
    })
    sortDates = dates.sort((a, b) => {
      return Number(a.split('-')[2]) - Number(b.split('-')[2])
    })
    return sortedDates.reverse()
  }

  async function getResultsData(resDate) {
    if (resDate == undefined) resDate = resultsDate
    const docRef = doc(db, "finalresults", resDate);
    const data = await getDoc(docRef);
    let districtList = []
    for (let d in data.data()){
      districtList.push(d)
    }

    districtList = districtList.sort()
    districtList = districtList.filter(el => {
      if (el == 'Nepal') return false
      return true
    })
    districtList.unshift('Nepal')
    setDistricts(districtList)
    setResultsData(data.data())
    assortData(data.data())
  }

  function assortData(rawdata, showAll = false){
    let rdata1 = [...rawdata[selectedDistrict].data]
    let rdata2 = [...rawdata[selectedDistrict].data]
    let data1 = rdata1.sort((a, b) => {
      return Number(b.totalCount) - Number(a.totalCount)
    })
    if (showAll) {
      setDisplay1(data1)
    } else {
      setDisplay1(data1.slice(0, 20))
    }
    let data2 = rdata2.sort((a, b) => {
      return Number(b.percentagePerUser) - Number(a.percentagePerUser)
    })
    if (showAll) {
      setDisplay2(data2)
    } else {
      setDisplay2(data2.slice(0, 20))
    }
  }

  return (
    <div className='results-container'>
      <div className='results-topsection'>
        <h3>Neighbourhood Bird Count Results</h3>
        {
          resultsDates != undefined &&
          <select className='selector' value={resultsDate} onChange={e => {
            setResultsDate(e.target.value)
            setRefresh(prev => !prev)
          }}>
            {
              resultsDates.map((el, i) => {
                return <option key={i} value={el}>{el}</option>
              })
            }
          </select>
        }
      </div>
      {
        resultsData != undefined &&
        <div className='results-mainsection'>
          <p className='results-text'>{resultsData[selectedDistrict].totalUsers} user(s) from</p>
          <select className='selector' value={selectedDistrict} onChange={e => {
            setSelectedDistrict(e.target.value)
            setRefresh2(prev => !prev)
          }}>
            {
              districts.map((el, i) => {
                return <option className='selector-option2' key={i} value={el} >{el}</option>
              })
            }
          </select>
        </div>
      }
      {
        display1 != undefined && display2 != undefined &&
        <div className='results-listcontainer'>
          <div className='results-list'>
            <p className='results-list-title'>Total Count</p>
            <div className='list-container'>
              {
                display1.map((el, i) => {
                  return <ListItem key={i} ordering={i} name={el.name} number={el.totalCount}/>
                })
              }
            </div>
          </div>
          <div className='results-list'>
            <p className='results-list-title b'>Percentage of Users</p>
            <p className='results-list-description'>(Percentage of users that have counted a bird)</p>
            <div className='list-container'>
              {
                display2.map((el, i) => {
                  return <ListItem key={i} ordering={i} name={el.name} number={`${el.percentagePerUser}%`}/>
                })
              }
            </div>
          </div>
        </div>
      }
      {
        selectedDistrict == 'Nepal' &&
        <div className='showmore-div'>
          {
            !showMore && resultsData != undefined ?
            <button onClick={()=>{
              assortData(resultsData, true)
              setShowMore(true)
            }}>Show All {resultsData[selectedDistrict].data.length} Birds</button>
            :
            <button onClick={()=>{
              setShowMore(false)
              assortData(resultsData, false)
            }}>Show Top 20 Birds</button>

          }
        </div>
      }
      {
        resultsData != undefined &&
        <MapSection data={resultsData} refresh={refresh}/>
      }
    </div>
  )
}

const MapSection = (props) => {
  const center = { lat: 28.129355, lng:83.966317 }
  const zoom = 7

  const [showMap, setShowMap] = useState(false)
  const [listOfBirds, setListOfBirds] = useState(undefined)
  const [selectedBird, setSelectedBird] = useState(undefined)
  const [mapData, setMapData] = useState(undefined)

  useEffect(()=>{
    let birds = []
    birds = listBirds(props.data)
    getLocForBirds(props.data, birds)
  }, [props.refresh])

  function listBirds(obj) {
    let birds = []
    for (let d in obj) {
      obj[d].data.forEach(bird => {
        if (birds.indexOf(bird.name) == -1) birds.push(bird.name)
      })
    }
    birds = birds.sort()

    setListOfBirds(birds)
    setSelectedBird(birds[0])
    return birds
  }

  function getLocForBirds(data, birds){
    let obj = {}
    birds.forEach(el => {
      let districList = getBirdLoc(el, data)
      obj[el] = districList
    })
    setMapData(obj)
  }

  function getBirdLoc(bird, data){
    let districts = []
    for (let d in data) {
      if (d !== 'Nepal') {
        data[d].data.forEach(el => {
          if (el.name == bird) districts.push(d)
        })
      }
    }
    let markerData = districts.map(el => {
      return {
        pos:districtLocations[el],
        icon:require('../components/map_icon_20.png')
      }
    })

    return markerData
  }

  function getMarkerData(bird) {
    let markers = []
    mapData[bird].forEach(el => {
      let obj = {
        pos:districtLocations[el],
        icon:require('../components/map_icon_20.png')
      }
      markers.push(obj)
    })
    return markers
  }


  return (
    <div className='map-container'>
      {
        listOfBirds != undefined &&
        <div className='map-selector-div'>
          <p className='results-list-title'>Map View of each bird</p>
          <select className='selector' value={selectedBird} onChange={e => {
            setSelectedBird(e.target.value)
          }}>
            {
              listOfBirds.map((el, i) => {
                return <option key={i} value={el} >{el}</option>
              })
            }
          </select>
        </div>
      }
      {
        mapData != undefined &&
        <p>The red dots on the map represent whether a specific bird has been counted in that district of Nepal.</p>
      }
      {
        mapData != undefined &&
        <Wrapper apiKey={"AIzaSyCxmPtHU4fo3lyNI_Xsj06vFKw-awuvqYo"} render={render}>
          <MapComponent center={center} zoom={zoom}>
            {
              mapData[selectedBird].map((el, i) => {
                return <Marker key={i} position={el.pos} icon={el.icon} />
              })
            }
          </MapComponent>
        </Wrapper>
      }

    </div>
  )
}

const IntroductionSection = (props) => {

  return (
    <div className='intro-section'>
      <h2>About the Neighbourhood Bird Count</h2>
      <p>
        This website displays the results from all the Neighbourhood Bird Counts. These were done by both experienced bird enthusiasts and beginners from all over Nepal, under Bird Conservation Nepal's Chhimeki Chara capaign.
      </p>
      <p>The Neighbourhood Bird Count is an event during which anyone can participate by using the Chhimeki Chara App to count the birds they see around them.</p>
      <p>
        For questions and more information, please visit BCN's website (<a href='https://birdlifenepal.org'>www.birdlifenepal.org</a>) or the Chhimeki Chara Facebook <a href='https://m.facebook.com/chhimekichara/'>page</a> or <a href='https://www.facebook.com/groups/673155714049751/'>group</a>.
      </p>
    </div>
  )
}


export default HomeScreen

const ListItem = (props) => {
  return (
    <div className='listitem'>
      <p className='listitem-title'>{props.ordering + 1}. {props.name}</p>
      <p className='listitem-number'>{props.number}</p>
    </div>
  )
}

const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

const districtLocations = {
  Achham:{
    lat:29.108585, lng:81.289895
  },
  Arghakhanchi:{
    lat:27.915678, lng:83.116978
  },
  Baglung:{
    lat:28.326996, lng:83.244232
  },
  Baitadi:{
    lat:29.521307, lng:80.552717
  },
  Bajura:{
    lat:29.531856, lng:81.565105
  },
  Banke:{
    lat:28.084933, lng:81.802177
  },
  Bara:{
    lat:27.091660, lng:85.062825
  },
  Bardiya:{
    lat:28.390001, lng:81.385196
  },
  Bhaktapur:{
    lat:27.676963, lng:85.437784
  },
  Chitwan:{lat:27.559598, lng:84.429110},
  Dailekh:{
    lat:28.883452, lng:81.684096
  },
  'Dang Deukhuri':{
    lat:27.941296, lng:82.436110
  },
  Darchula:{
    lat:29.865634, lng:80.803146
  },
  Dhading:{
    lat:27.881214, lng:84.892483
  },
  Dhankuta:{
    lat:26.985482, lng:87.319325
  },
  Dhanusha:{
    lat:26.827952, lng:86.037344
  },
  Gorkha:{
    lat:28.292048, lng:84.805374
  },
  Gulmi:{
    lat:28.084941, lng:83.338010
  },
  Ilam:{
    lat:26.867957, lng:87.884179
  },
  Jajarkot:{
    lat:28.871468, lng:82.156022
  },
  Jhapa:{
    lat:26.572633, lng:87.908791
  },
  Jumla:{
    lat:29.268660, lng:82.226732
  },
  Kailali:{
    lat:28.727712, lng:80.886118
  },
  Kanchanpur:{
    lat:28.855363, lng:80.300532
  },
  Kapilvastu:{
    lat:27.627469, lng:82.971290
  },
  Kaski:{
    lat:28.366129, lng:83.975634
  },
  Kathmandu:{
    lat:27.701803, lng:85.320695
  },
  Kavrepalanchok:{
    lat:27.532720, lng:85.604959
  },
  Khotang:{
    lat:27.172175, lng:86.787905
  },
  Lalitpur:{
    lat:27.541443, lng:85.333950
  },
  Lamjung:{
    lat:28.298639, lng:84.443272
  },
  Mahottari:{
    lat:26.870001, lng:85.809221
  },
  Makwanpur:{
    lat:27.505883, lng:85.048297
  },
  Manang:{
    lat:28.701287, lng:84.189365
  },
  Morang:{
    lat:26.619345, lng:87.458962
  },
  Mugu:{
    lat:29.616282, lng:82.459702
  },
  Mustang:{
    lat:28.990066, lng:83.882697
  },
  Myagdi:{
    lat:28.557669, lng:83.429303
  },
  Nawalpur:{
    lat:27.675897, lng:84.102797
  },
  Nuwakot:{
    lat:27.888808, lng:85.235806
  },
  Palpa:{
    lat:27.811775, lng:83.588297
  },
  Panchthar:{
    lat:27.123354, lng:87.795991
  },
  Parsa:{
    lat:27.258942, lng:84.796911
  },
  Ramechhap:{
    lat:27.389937, lng:86.135406
  },
  Rasuwa:{
    lat:28.184194, lng:85.406112
  },
  Rolpa:{
    lat:28.358230, lng:82.609310
  },
  Rupandehi:{
    lat:27.586984, lng:83.380348
  },
  Sarlahi:{
    lat:26.979520, lng:85.554824
  },
  Sindhuli:{
    lat:27.218635, lng:85.970588
  },
  Siraha:{
    lat:26.732750, lng:86.350263
  },
  Solukhumbu:{
    lat:27.673302, lng:86.734770
  },
  Sunsari:{
    lat:26.664052, lng:87.179239
  },
  Surkhet:{
    lat:28.599210, lng:81.598122
  },
  Syangja:{
    lat:28.014660, lng:83.831751
  },
  Tanahun:{
    lat:27.944019, lng:84.239185
  },
  Taplejung:{
    lat:27.537163, lng:87.795432
  },
  Udayapur:{
    lat:26.885215, lng:86.646862
  },
  "Western Rukum":{
    lat:28.733627, lng:82.453212
  },
  "West Rukum":{
    lat:28.733627, lng:82.453212
  },
  Sindhupalchok:{
    lat:27.924217, lng:85.736764
  },
  Doti:{
    lat:29.152828, lng:80.866002
  },
  Bhojpur:{
    lat:27.174684, lng:87.083674
  },
  Parbat:{
    lat:28.228891, lng:83.714195
  },
  Bajhang:{
    lat:29.685279, lng:81.200708
  },
  Humla:{
    lat:30.034349, lng:81.926098
  },
  Dolakha:{
    lat:27.766691, lng:86.198801
  },
  Sankhuwasabha:{
    lat:27.589698, lng:87.272765
  },
  Okhaldhunga:{
    lat:27.307106, lng:86.433756
  },

}