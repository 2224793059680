import React, {useState, useEffect, useRef} from 'react'

import { Wrapper, Status } from "@googlemaps/react-wrapper";

const MapComponent = ({center, zoom, children}) => {
    const [map, setMap] = useState()
    const ref = useRef()

    useEffect(()=>{
        if (!map) {
            setMap(new window.google.maps.Map(ref.current, {center, zoom}))
        }
    }, [ref, map])

    return (
        <div id='map'>
            <div 
                ref={ref}
                id='map'
            />
            {
                React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, { map });
                    }
                })
            }
        </div>
    )
}

export default MapComponent;